<template>
<div class="instagram-item" :width="thumbnail.width" >
      <div class="instagram-container">
            <div>
                  <b-overlay @click="cliked" :show="showoverlay" v-b-hover="callback" variant="dark" opacity="0.5">
                        <img :src="thumbnail.src" :width="thumbnail.width" :height="thumbnail.height" />
                        <template #overlay>
                              <span class="ml-2 mr-2">
                                    <inline-svg :src="svg('like.svg')" /> {{likes}}
                              </span>
                              <span class="ml-2 mr-2">
                                    <inline-svg :src="svg('comment.svg')" /> {{comments}}
                              </span>
                        </template>
                  </b-overlay>
            </div>
      </div>      
</div>
</template>

<script>
export default {
      name: 'InstagramItem',
      components: {},
      props: ["item"],
      data() {
            return {
                  instagram: this.item,
                  showoverlay: false,
            };
      },
      computed: {
            thumbnail() {
                 return this.$instagram.thumbnail(this.item);
            },
            likes() {
                  return this.$instagram.likes(this.item);
            },
            comments() {
                 return this.$instagram.comments(this.item);
            },
            caption() {
                  return this.$instagram.caption(this.item);                  
            },
            timetaken() {
                  var adate = this.$instagram.timetaken(this.item);
                  var datestring = this.$date.dateNowWithoutTime(adate);
                  return this.$date.stringToReadableFormat(datestring);
            }
      },
      methods: {
            svg(path) {
                  return this.$images.getSvgUrl(path);
            },
            callback(ishover) {
                  if (ishover) {
                        this.showoverlay = true;
                  } else {
                        this.showoverlay = false;
                  }
            },
            cliked()
            {
                  this.$emit("clicked", this.instagram);
            }
      }
};
</script>
